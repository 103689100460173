/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"

const LinkBubble = ({title,to}) => {
  return (
    <div style={{
        marginBottom: rhythm(1 / 4),  
        marginRight: rhythm(1 / 4),
        display: "inline-Block" 
      }}>
        <h3><Link style={{backgroundColor: "orange", padding: "4px", borderRadius:"4px", color:"black" }} to={to}>{title}</Link></h3>
    </div>
  )
}

export default LinkBubble
